<template>
  <div class="container">
    <van-form @submit="onSubmit">
      <van-field
        readonly
        required
        clickable
        name="campus"
        :value="campus.label"
        label="所属校区"
        placeholder="请选择所属校区"
        right-icon="arrow"
        :rules="[{ required: true, message: '所属校区不能为空' }]"
        @click="showPicker = true"
      />
      <van-field
        v-model="content"
        required
        name="content"
        type="textarea"
        placeholder="请写下您的意见反馈"
        rows="10"
        autosize
        :rules="[{ required: true, message: '意见反馈不能为空' }]"
      />
      <van-field
        name="uploaImgs"
        :border="false"
        :rules="[{ required: false, message: '意见图片不能为空' }]"
      >
        <template #input>
          <van-uploader v-model="uploaImgs" max-count="3" :after-read="afterRead" />
        </template>
      </van-field>
      <div class="image-tips">最多可上传3张图片</div>

      <div style="margin: 16px;">
        <van-button block type="primary" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>

    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        value-key="label"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>
<script>

import PageMixin from '@/mixins/page'
import { Form, Field, Picker, Uploader } from 'vant'
import upload from '@/utils/upload'

export default {
  name: 'Suggestion',
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Picker.name]: Picker,
    [Uploader.name]: Uploader
  },
  mixins: [PageMixin],
  data() {
    return {
      content: '',
      uploaImgs: [],
      columns: [],
      campus: { label: '', value: -1 },
      showPicker: false
    }
  },
  created() {
    this.$api.filter_studios().then(res => {
      this.columns = res.data.map(item => ({
        label: item.studio_name,
        value: item.studio_id
      }))
    })
  },
  methods: {
    onSubmit(form) {
      console.log('submit', form)
      const params = {
        studio_id: this.campus.value,
        content: form.content,
        images: form.uploaImgs.map(item => item.url)
      }
      this.$toast.loading({
        mask: true,
        message: '提交中...',
        duration: 0
      })
      this.$api.suggestion_add(params)
        .then(res => {
          this.$toast.success('提交意见成功')
          setTimeout(() => {
            this.$router.back()
          }, 1500)
        })
        .catch(err => {
          this.$toast.fail('提交意见失败')
          console.error(err)
        })
    },
    afterRead(file, detail) {
      file.status = 'uploading'
      file.message = '上传中...'
      upload({ file: file.file })
        .then(res => {
          this.uploaImgs.splice(detail.index, 1, { url: res.path, status: 'done', message: '上传成功' })
        })
        .catch(err => {
          console.error(err)
          file.status = 'failed'
          file.message = '上传失败'
        })
    },
    onCampusShow() {
      this.showPicker = true
    },
    onCampusClose() {
      this.showPicker = false
    },
    onConfirm(value) {
      this.showPicker = false
      this.campus = value
    }
  }
}
</script>

<style lang="less" scoped>
  .container{
    padding-top: 10px;
  }

   .image-tips{
    padding: 10px 16px;
    font-size: 14px;
    color: #666;
    background: #fff;
  }
</style>

